import { createSlice } from '@reduxjs/toolkit'

export const usersToFollowReducer = createSlice({
  name: 'usersToFollow',
  initialState: {
    paginationData: null,
    items: [],
    hasMore: false,
  },
  reducers: {
    updateUsersToFollow: (state, action) => {
      const data = action.payload;
      state.paginationData = data;
      if(data.current_page===1) {
        state.items = data.data;
      } else {
        state.items = [...state.items, ...data.data];
      }
      state.hasMore = data.next_page_url!=null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateUsersToFollow } = usersToFollowReducer.actions

export default usersToFollowReducer.reducer