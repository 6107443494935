import { Helper } from '../helpers/Helper';
import axiosInstance from '../helpers/axiosInstance';

export default class DaoService {
    prefix = '';
    constructor(prefix) {
        this.prefix = prefix;
    }

    store(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(this.prefix, data)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    };

    update(data, idOrUid) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`${this.prefix}/${idOrUid}`, data)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    };

    paginateAll(size) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${this.prefix}/${size}/paginate`)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    findAll() {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${this.prefix}`)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    find(uid) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${this.prefix}/${uid}`)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    delete(uid) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(`${this.prefix}/${uid}`)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    static get(url) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`${url}`)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    static post(url, data, config = null) {
        return new Promise((resolve, reject) => {
            axiosInstance.post(`${url}`, data, config)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    static put(url, data) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`${url}`, data)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }

    static delete(url) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(url)
                .then((response) => {
                    let content = Helper.handleResponse(response);
                    resolve(content);
                }).catch((err) => {
                    Helper.handleError(err);
                    reject(err);
                });
        });
    }
}
