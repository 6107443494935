import axios from 'axios';
import { getAuthToken } from './tokenManager';

// Configurer l'instance d'axios avec l'en-tête "accept" et le token d'authentification
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  headers: {
    'Accept': 'application/json',
  }
});

// Ajouter un intercepteur pour mettre à jour l'en-tête "Authorization"
axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken();
    if (authToken) {
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;