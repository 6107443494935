import { configureStore } from '@reduxjs/toolkit'
import actualiteReducer from './actualite/actualiteReducer'
import articleLatestsReducer from './article/articleLatestsReducer'
import coursLatestsReducer from './cours/coursLatestsReducer'
import publishedCourseReducer from './cours/publishedCourseReducer'
import usersToFollowReducer from './user/usersToFollowReducer'
import categorieWithPopularCoursesReducer from './cours/categorieWithPopularCoursesReducer';

export default configureStore({
  reducer: {
    publishedCourses: publishedCourseReducer,
    usersToFollow: usersToFollowReducer,
    actualites: actualiteReducer,
    coursLatests: coursLatestsReducer,
    articleLatests: articleLatestsReducer,
    categorieWithPopCourses: categorieWithPopularCoursesReducer,
  },
})