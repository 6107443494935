import DaoService from './DaoService';

export default class ActualiteService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!ActualiteService.instance) {
            ActualiteService.instance = new ActualiteService({ prefix: '/actualite' });
        }
        return ActualiteService.instance;
    }

    findByUser(uid) {
        return DaoService.get(`${this.prefix}/${uid}/user`);
    }

    findForPublic() {
        return DaoService.get(`/opened${this.prefix}/public`);
    }
}
