import { createSlice } from '@reduxjs/toolkit'

export const actualitesReducer = createSlice({
  name: 'actualites',
  initialState: {
    paginationData: null,
    items: [],
    hasMore: false,
  },
  reducers: {
    updateActualites: (state, action) => {
      const paginationData = action.payload;
      state.paginationData = paginationData;
      if (paginationData.current_page === 1) {
        state.items = paginationData.data;
      } else {
        // add unique items to the list
        const newItems = paginationData.data.filter((item) => {
          return state.items.findIndex((i) => i.id === item.id) === -1;
        });
        state.items = [...state.items, ...newItems];
      }
      state.hasMore = paginationData.next_page_url != null;
    },
    firstLoadActualites: (state, action) => {
      const paginationData = action.payload;
      state.paginationData = paginationData;
      state.items = paginationData.data;
      state.hasMore = paginationData.next_page_url != null;
    },
    addOneActualite: (state, action) => {
      const actualite = action.payload;
      state.items = [actualite, ...state.items];
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateActualites, firstLoadActualites, addOneActualite } = actualitesReducer.actions

export default actualitesReducer.reducer