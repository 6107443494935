import DaoService from './DaoService';
export default class RelationService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!RelationService.instance) {
            RelationService.instance = new RelationService({ prefix: '/relation' });
        }
        return RelationService.instance;
    }

    accepter(uid) {
        return DaoService.post(`${this.prefix}/${uid}/accept`);
    }

    refuser(uid) {
        return DaoService.post(`${this.prefix}/${uid}/refuse`);
    }

    pendingSent(uid) {
        return DaoService.get(`${this.prefix}/${uid}/pending-sent`);
    }

    pendingReceived(uid) {
        return DaoService.get(`${this.prefix}/${uid}/pending-received`);
    }

    getFriends(uid) {
        return DaoService.get(`${this.prefix}/${uid}/friends`);
    }

    // get relation between two users
    getRelationBetweenTwoUsers(uid1, uid2) {
        return DaoService.get(`${this.prefix}/${uid1}/user/${uid2}`);
    }
}
