import React from "react";
import { Drawer, Result, Spin } from "antd";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { UserContext } from "../../context/user_context";
import { MeetingContext } from "../../context/meeting_context";
import "./MeetingDrawer.scss";

const MeetingDrawer = () => {
  const { user } = React.useContext(UserContext);
  const [externalApi, setExternalApi] = React.useState(null);
  const { currentMeeting, setCurrentMeeting } =
    React.useContext(MeetingContext);
  const [isVisible, setIsVisible] = React.useState(false);
  const [spinning, setSpinning] = React.useState(true);

  const onClose = () => {
    setIsVisible(false);
  };

  React.useEffect(() => {
    setIsVisible(!!currentMeeting?.room_name);
    if (currentMeeting) {
      setSpinning(true);
    }
  }, [currentMeeting]);

  React.useEffect(() => {
    if (!user && externalApi) {
      externalApi?.executeCommand("hangup");
    }
    if (externalApi && user?.formatted_name) {
      externalApi.executeCommand("displayName", user.formatted_name);
    }
  }, [user, externalApi]);

  React.useEffect(() => {
    // set current user as moderator if user is the owner of the meeting
    if (user?.id === currentMeeting?.user_id && externalApi) {
      externalApi.executeCommand("toggleLobby", true);
    }
  }, [externalApi, user, currentMeeting]);

  const handleApiReady = (api) => {
    setExternalApi(api);
    // api.addEventListener(...)
    // api.executeCommand(...)
    api.executeCommand("email", user.email);
    // when leaving the page, close the meeting
    api.addEventListener("readyToClose", () => {
      setCurrentMeeting(null);
      setIsVisible(false);
    });
    // après 2 secondes, on arrête le spinner
    setTimeout(() => {
      setSpinning(false);
    }, 5000);
  };

  return (
    <div className="meeting-drawer">
      <Drawer
        destroyOnClose={false}
        title={currentMeeting?.titre}
        placement="right"
        closable={false}
        onClose={onClose}
        open={isVisible}
        width="70%"
      >
        <Spin spinning={spinning}>
          {currentMeeting && currentMeeting.room_name && user ? (
            <JitsiMeeting
              domain={process.env.REACT_APP_JITSI_APP_DOMAIN}
              roomName={currentMeeting.room_name}
              configOverwrite={{
                startWithAudioMuted: true,
                disableModeratorIndicator: true,
                startScreenSharing: true,
                enableEmailInStats: true,
                enableClosePage: false,
                enableWelcomePage: false,
                prejoinPageEnabled: false,
                enableNoAudioDetection: true,
                enableNoisyMicDetection: true,
              }}
              interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              }}
              userInfo={{
                displayName: user.formatted_name,
              }}
              onApiReady={handleApiReady}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = "80vh";
              }}
            />
          ) : (
            <Result status="404" title="Aucune réunion active" />
          )}
        </Spin>
      </Drawer>
      {!isVisible && currentMeeting && currentMeeting.room_name ? (
        <button
          onClick={() => setIsVisible(true)}
          id="floatingButton"
          className="floating-btn"
        >
          <i className="fa fa-video"></i>
        </button>
      ) : null}
    </div>
  );
};

export default MeetingDrawer;
