import moment from "moment";
import Toastr from "./Toastr";
import { htmlToText } from "html-to-text";

export class Helper {
  static handleResponse(response) {
    response = response.data;
    if (response.error) {
      // erreur
      if (response.validation_error) {
        //erreur de validation
        response.content.forEach((error) => {
          Toastr.error(error);
        });
      } else {
        // error custom
        Toastr.error(response.content);
      }
      throw new Error(`Une erreur s'est produite...`);
    }
    // pas d'erreur
    return response.content;
  }

  static handleError(err) {
    if (!err.response) return;
    let response = err.response.data;
    if (response.error) {
      // erreur
      if (response.validation_error) {
        //erreur de validation
        response.content.forEach((error) => {
          Toastr.error(error);
        });
      } else {
        console.log(response);
        // error custom
        Toastr.error(response.content);
      }
      // throw new Error(`Une erreur s'est produite...`);
    }
    // pas d'erreur
    return response.content;
  }

  static truncateText = (htmlContent, maxLength = 100) => {
    const plainText = htmlToText(htmlContent, { wordwrap: null });
    if (plainText.length > maxLength) {
      return plainText.slice(0, maxLength) + "...";
    }
    return plainText;
  };

  static getImgAsBase64 = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/png");
  };

  static base64ToBlob(base64Data) {
    const parts = base64Data.split(";");
    const mime = parts[0].split(":")[1];
    const raw = window.atob(parts[1].split(",")[1]);

    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mime });
  }

  static formatDate(date, format = "DD/MM/YYYY HH:mm") {
    return moment(date).format(format);
  }

  static isScreenSize = (size) => {
    const screenWidth = window.innerWidth;
    switch (size) {
      case "mobile":
        return screenWidth <= 480;
      case "tablet":
        return screenWidth <= 768;
      case "desktop":
        return screenWidth > 768;
      default:
        return false;
    }
  };

  static playNotificaitonSound = () => {
    const audio = new Audio(
      process.env.PUBLIC_URL + "/sounds/educrak-notification-sound.wav"
    );
    audio.load();
    audio.play();
  };

  /**
   * transform un nom en tag en remplaçant les espaces par des vides et en supprimant les caractères spéciaux
   */
  static tagify = (name) => {
    return name.replace(/\s+/g, "").toLowerCase();
  };

  static formatDurationFromSeconds = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    let formattedTimeLeft = "";

    if (duration.hours() > 0) {
      formattedTimeLeft += `${duration.hours()}h `;
    }
    if (duration.minutes() > 0 || duration.hours() > 0) {
      formattedTimeLeft += `${duration.minutes()}mn `;
    }
    formattedTimeLeft += `${duration.seconds()}s`;
    return formattedTimeLeft;
  };
}
