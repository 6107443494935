import { createSlice } from '@reduxjs/toolkit'

export const publishedCourseReducer = createSlice({
  name: 'publishedCourses',
  initialState: {
    paginationData: null,
    items: [],
    hasMore: false,
  },
  reducers: {
    updatePublishedCourses: (state, action) => {
      const data = action.payload;
      state.paginationData = data.paginationData;
      if(data.paginationData.current_page===1) {
        state.items = data.courses;
      } else {
        state.items = [...state.items, ...data.courses];
      }
      state.hasMore = data.paginationData.next_page_url!=null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updatePublishedCourses } = publishedCourseReducer.actions

export default publishedCourseReducer.reducer