import DaoService from './DaoService';
export default class NotificationService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService({ prefix: '/notification' });
        }
        return NotificationService.instance;
    }

    findUnreadByUser(uid) {
        return DaoService.get(`${this.prefix}-unread/${uid}`);
    }

    findAllByUser(uid) {
        return DaoService.get(`${this.prefix}-all/${uid}`);
    }

    read(id) {
        return DaoService.put(`${this.prefix}-read/${id}`);
    }

    readAll() {
        return DaoService.put(`${this.prefix}-read-all`);
    }

}
