import DaoService from './DaoService';

export default class UserDeviceService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!UserDeviceService.instance) {
            UserDeviceService.instance = new UserDeviceService({ prefix: '/userdevice' });
        }
        return UserDeviceService.instance;
    }
}
