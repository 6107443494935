import DaoService from './DaoService';
import axiosInstance from '../helpers/axiosInstance';
export default class UserService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!UserService.instance) {
            UserService.instance = new UserService({ prefix: '/user' });
        }
        return UserService.instance;
    }

    static getCurrentUser() {
        return axiosInstance.get('/current-user');
    }

    static login(loginData) {
        return DaoService.post('/login', loginData);
    }

    static register(registerData) {
        return DaoService.post('/auth/register', registerData);
    }

    static preregister(registerData) {
        return DaoService.post('/auth/preregister', registerData);
    }

    static logout() {
        return DaoService.post('/logout', null);
    }

    static verifyUserEmail(email) {
        return DaoService.post(`/auth/${email}/verify-email`, null);
    }

    static findUsersToFollow() {
        return DaoService.get(`/opened/users-to-follow`);
    }

    // activer l'utilisateur
    enable(uid) {
        return DaoService.post(`${this.prefix}/${uid}/enable`, null);
    }

    // desactiver l'utilisateur
    disable(uid) {
        return DaoService.post(`${this.prefix}/${uid}/disable`, null);
    }

    getPublicProfile(uid) {
        return DaoService.get(`/opened${this.prefix}-profile/${uid}`);
    }

    getStats(uid) {
        return DaoService.get(`${this.prefix}/${uid}/stats`);
    }

    getInstructorStats(uid) {
        return DaoService.get(`${this.prefix}/${uid}/get-instructor-stats`);
    }

    getPapersStats(uid) {
        return DaoService.get(`${this.prefix}/${uid}/get-papers-stats`);
    }

    search(searchText) {
        return DaoService.post(`${this.prefix}-search`, { searchText });
    }

    uploadBanner(image, uid) {
        return DaoService.post(`${this.prefix}/${uid}/upload-banner`, image);
    }

    updateCvInfo(data, uid) {
        return DaoService.put(`${this.prefix}/${uid}/update-cv-info`, data);
    }

    updatePassword(data) {
        return DaoService.put(`/auth/change-password`, data);
    }

    updatePhoneNumber(uid, newPhoneNumber) {
        return DaoService.put(`${this.prefix}/${uid}/update-phone-number`, { telephone: newPhoneNumber });
    }

    findFriendsToInviteForGroupe(groupeUid) {
        return DaoService.get(`${this.prefix}/${groupeUid}/groupe/friends-to-invite`);
    }

    uploadPhoto(formData, uid) {
        return DaoService.post(`${this.prefix}/${uid}/upload-photo`, formData);
    }
}
