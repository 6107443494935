import { createSlice } from '@reduxjs/toolkit'

export const coursLatestsReducer = createSlice({
  name: 'coursLatests',
  initialState: {
    paginationData: null,
    items: [],
    hasMore: false,
  },
  reducers: {
    updateLatestCourses: (state, action) => {
      const data = action.payload;
      state.items = data;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateLatestCourses } = coursLatestsReducer.actions

export default coursLatestsReducer.reducer