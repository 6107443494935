import React, { Suspense, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import { ConfigProvider } from "antd";
import frFR from "antd/locale/fr_FR";

// Import Css Here
import "./assets/scss/style.scss";
import "antd/dist/reset.css";
import "antd/dist/antd";

import { UserContext } from "./context/user_context";
import { Helper } from "./helpers/Helper";
import UserService from "./services/userService";
import { UnreadNotificationsContext } from "./context/unread_notifications_context";
import NotificationService from "./services/notificationService";
import { useDispatch } from "react-redux";
import { addOneActualite } from "./store/actualite/actualiteReducer";
import moment from "moment";
import "moment/locale/fr";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import Toastr from "./helpers/Toastr";
import MessageService from "./services/messageService";
import { io } from "socket.io-client";
import ActualiteService from "./services/actualiteService";
import RelationService from "./services/relationService";
import MeetingDrawer from "./components/meet/MeetingDrawer";
import { MeetingContext } from "./context/meeting_context";
import { MessagingContext } from "./context/messaging_context";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { messaging, firebaseConfig } from "./firebase/firebaseConfig";
import { getToken } from "firebase/messaging";
import UserDeviceService from "./services/userDeviceService";



// Lazy loaded components
const LoginPage = React.lazy(() => import("./pages/auth/LoginPage"));
const BecomeInstructor = React.lazy(() =>
  import("./pages/instructor/BecomeInstructorPage")
);
const DemandeInstructeurList = React.lazy(() =>
  import("./pages/instructor/DemandeInstructeurListPage")
);
const InstructeurCoursList = React.lazy(() =>
  import("./pages/cours/InstructeurCoursListPage")
);
const CoursConceptionShow = React.lazy(() =>
  import("./pages/cours/CoursConceptionShowPage")
);
const DemandePublicationCoursAdminListPage = React.lazy(() =>
  import(
    "./pages/cours/demande-publication/DemandePublicationCoursAdminListPage"
  )
);
const CoursDeroulement = React.lazy(() =>
  import("./pages/cours/deroulement/CoursDeroulementPage")
);
const ApprenantSouscriptionList = React.lazy(() =>
  import("./pages/souscription/ApprenantSouscriptionListPage")
);
const CoursFavoriList = React.lazy(() =>
  import("./pages/cours/CoursFavoriListPage")
);
const InstructeurSpacePage = React.lazy(() =>
  import("./pages/instructor/InstructeurSpacePage")
);
const UserProfil = React.lazy(() => import("./pages/auth/UserProfilPage"));
const UserAdminList = React.lazy(() =>
  import("./pages/auth/UserAdminListPage")
);
const CoursAdminList = React.lazy(() =>
  import("./pages/cours/CoursAdminListPage")
);
const ReviewsAdminList = React.lazy(() =>
  import("./components/reviews/ReviewsAdminList")
);
const EvenementNew = React.lazy(() =>
  import("./pages/evenement/EvenementNewPage")
);
const EvenementConfig = React.lazy(() =>
  import("./pages/evenement/EvenementConfigPage")
);
const EvenementEdit = React.lazy(() =>
  import("./pages/evenement/EvenementEditPage")
);
const UserEvenementList = React.lazy(() =>
  import("./pages/evenement/UserEvenementListPage")
);
const EvenementDetailsPage = React.lazy(() =>
  import("./pages/evenement/EvenementDetailsPage")
);
const AdminEventList = React.lazy(() =>
  import("./pages/evenement/AdminEventListPage")
);
const InstructeurClasseList = React.lazy(() =>
  import("./pages/classe/InstructeurClasseListPage")
);
const ClasseConfigPage = React.lazy(() =>
  import("./pages/classe/ClasseConfigPage")
);
const ClasseDetails = React.lazy(() =>
  import("./pages/classe/ClasseDetailsPage")
);
const JoinClassePage = React.lazy(() =>
  import("./pages/classe/apprenant/JoinClassePage")
);
const ApprenantClasseList = React.lazy(() =>
  import("./pages/classe/ApprenantClasseListPage")
);
const UserConsentements = React.lazy(() =>
  import("./pages/auth/UserConsentementsPage")
);
const ClasseChapitreDeroulementPage = React.lazy(() =>
  import("./pages/classe/cours/module/chapitre/ClasseChapitreDeroulementPage")
);
const CoursChapitreDeroulementPage = React.lazy(() =>
  import("./pages/cours/deroulement/CoursChapitreDeroulementPage")
);
const EvaluationConfigPage = React.lazy(() =>
  import("./pages/evaluation/EvaluationConfigPage")
);
const EvaluationNewPage = React.lazy(() =>
  import("./pages/evaluation/EvaluationNewPage")
);
const AdminEvaluationListPage = React.lazy(() =>
  import("./pages/evaluation/AdminEvaluationListPage")
);
const InstructeurEvaluationListPage = React.lazy(() =>
  import("./pages/evaluation/InstructeurEvaluationListPage")
);
const EvaluationDeroulementPage = React.lazy(() =>
  import("./pages/evaluation/EvaluationDeroulementPage")
);
const NotificationListPage = React.lazy(() =>
  import("./pages/notification/NotificationListPage")
);
const PublicProfilePage = React.lazy(() =>
  import("./pages/auth/PublicProfilePage")
);
const PaymentResult = React.lazy(() =>
  import("./pages/payment/PaymentResultPage")
);
const GroupeNewPage = React.lazy(() => import("./pages/groupe/GroupeNewPage"));
const GroupeDetailsPage = React.lazy(() =>
  import("./pages/groupe/GroupeDetailsPage")
);
const GroupeEditPage = React.lazy(() =>
  import("./pages/groupe/GroupeEditPage")
);
const ChapitrePublicContentPage = React.lazy(() =>
  import("./pages/chapitre/ChapitrePublicContentPage")
);
const NotificationReminder = React.lazy(() =>
  import("./components/utils/NotificationReminder")
);
const PublicationShowPage = React.lazy(() =>
  import("./pages/publication/PublicationShowPage")
);
const CourseByCategoriePage = React.lazy(() =>
  import("./pages/actualite/CourseByCategoriePage")
);
const PublicationEditPage = React.lazy(() =>
  import("./pages/publication/PublicationEditPage")
);
const TacheListPage = React.lazy(() => import("./pages/tache/TacheListPage"));
const TacheDrawer = React.lazy(() => import("./components/tache/TacheDrawer"));
const ProjetDrawer = React.lazy(() =>
  import("./components/projet/ProjetDrawer")
);
const CampagneDetailsPage = React.lazy(() =>
  import("./pages/campagne/CampagneDetailsPage")
);
const CommentAdminList = React.lazy(() =>
  import("./components/comment/CommentAdminList")
);
const CommentaireDetailsRedirect = React.lazy(() =>
  import("./pages/comment/CommentaireDetailsRedirectPage")
);
const CvBuilderPage = React.lazy(() => import("./pages/cv/CvBuilderPage"));
const CvAdminList = React.lazy(() => import("./pages/cv/CvAdminListPage"));
const EventHome = React.lazy(() => import("./pages/evenement/EventHomePage"));
const ElearningHome = React.lazy(() =>
  import("./pages/elearning/ElearningHomePage")
);
const ElearningCourseCategorieList = React.lazy(() =>
  import("./pages/elearning/ElearningCourseCategorieListPage")
);
const PapersHome = React.lazy(() => import("./pages/papers/PapersHomePage"));
const ReseauHome = React.lazy(() => import("./pages/reseau/ReseauHomePage"));
const HeaderMain = React.lazy(() => import("./common/header/HeaderMain"));
const MessaginDetailsHomePage = React.lazy(() =>
  import("./pages/conversation/MessagingDetailsHomePage")
);
const BottomNav = React.lazy(() => import("./components/footer/BottomNav"));
const EcoleNewPage = React.lazy(() => import("./pages/ecole/EcoleNewPage"));
const EcoleHome = React.lazy(() => import("./pages/ecole/EcoleHomePage"));
const EcoleDetailsPage = React.lazy(() =>
  import("./pages/ecole/EcoleDetailsPage")
);
const CourseDetailsPage = React.lazy(() =>
  import("./pages/cours/CoursDetailsPage")
);
const CoursEditPage = React.lazy(() => import("./pages/cours/CoursEditPage"));
const ArticleNewPage = React.lazy(() =>
  import("./pages/article/ArticleNewPage")
);
const ArticleShowPage = React.lazy(() =>
  import("./pages/article/ArticleShowPage")
);
const ArticleEditPage = React.lazy(() =>
  import("./pages/article/ArticleEditPage")
);
const InstructeurArticleListPage = React.lazy(() =>
  import("./pages/article/InstructeurArticleListPage")
);
const InstructorConsentementsPage = React.lazy(() =>
  import("./pages/auth/InstructorConsentementsPage")
);
const CampagneAdminListPage = React.lazy(() =>
  import("./pages/campagne/CampagneAdminListPage")
);
const CoursNewPage = React.lazy(() => import("./pages/cours/CoursNewPage"));
const MessagingHomePage = React.lazy(() =>
  import("./pages/conversation/MessagingHomePage")
);
const CvPublicPreviewPage = React.lazy(() =>
  import("./pages/cv/CvPublicPreviewPage")
);
const CouponPromotionListPage = React.lazy(() =>
  import("./pages/couponpromotion/CouponPromotionListPage")
);
const DemandeSupportTutoratAdminListPage = React.lazy(() =>
  import(
    "./pages/cours/demande-support-tutorat/DemandeSupportTutoratAdminListPage"
  )
);
const DemandeSupportTutoratDetailsPage = React.lazy(() =>
  import(
    "./pages/cours/demande-support-tutorat/DemandeSupportTutoratDetailsPage"
  )
);
const ParcoursAdminListPage = React.lazy(() =>
  import("./pages/parcours/ParcoursAdminListPage")
);
const ParcoursAdminShowPage = React.lazy(() =>
  import("./pages/parcours/ParcoursAdminShowPage")
);
const ParcoursPublicDetailsPage = React.lazy(() =>
  import("./pages/parcours/ParcoursPublicDetailsPage")
);
const ParcoursAapprenantListPage = React.lazy(() =>
  import("./pages/parcours/ParcoursApprenantListPage")
);
const SkeletonAnimated = React.lazy(() =>
  import("./components/utils/SkeletonAnimated")
);
const CategorieChoicesPage = React.lazy(() =>
  import("./pages/categorie/CategorieChoicesPage")
);
const FollowUsersPage = React.lazy(() =>
  import("./pages/user/FollowUsersPage")
);
const MeetHomePage = React.lazy(() => import("./pages/meet/MeetHomePage"));
const MeetConfigPage = React.lazy(() => import("./pages/meet/MeetConfigPage"));
const MeetAdminListPage = React.lazy(() =>
  import("./pages/meet/MeetAdminListPage")
);
// const Banner = React.lazy(() => import("./components/utils/Banner"));
const QuizHomePage = React.lazy(() =>
  import("./pages/evaluation/QuizHomePage")
);
const MessagingDrawer = React.lazy(() =>
  import("./components/conversation/MessagingDrawer")
);
const FeedHomePage = React.lazy(() => import("./pages/actualite/FeedHomePage"));
const GroupeHomePage = React.lazy(() => import("./pages/groupe/GroupeHomePage"));
const TacheHomePage = React.lazy(() => import("./pages/tache/TacheHomePage"));
const ClasseHomePage = React.lazy(() => import("./pages/classe/ClasseHomePage"));
const ParcoursHomePage = React.lazy(() =>
  import("./pages/parcours/ParcoursHomePage")
);

// Pour définir le style de la barre de statut à sombre
async function setStatusBarDark() {
  await StatusBar.setStyle({ style: Style.Dark });
}

// Pour définir la couleur de fond de la barre de statut
async function setStatusBarColor() {
  await StatusBar.setBackgroundColor({ color: "#3C6DFE" });
}

// Pour empêcher la barre de statut de chevaucher le contenu de l'application
async function preventStatusBarOverlay() {
  await StatusBar.setOverlaysWebView({ overlay: false });
}

const showStatusBar = async () => {
  await StatusBar.show();
};

function App() {
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [pendingRelations, setPendingRelations] = useState([]);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [showMessagingDrawer, setShowMessagingDrawer] = useState(false);

  const dispatch = useDispatch();
  moment().locale("fr");

  useEffect(() => {
    // Configurez la barre de statut lorsque le composant est monté
    if (Capacitor.isPluginAvailable("StatusBar")) {
      setStatusBarDark();
      setStatusBarColor();
      preventStatusBarOverlay();
      showStatusBar();
    }
  }, []);

  useEffect(() => {
    if (!user) return;
    async function requestPermission() {
      if (Notification.permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: firebaseConfig.vapidKey,
        });

        UserDeviceService.getInstance()
          .store({
            name: navigator.appName,
            version: navigator.appVersion,
            language: navigator.language,
            platform: navigator.platform,
            user_agent: navigator.userAgent,
            token,
          })
          .then(() => {
            // console.log("Device info stored : ", response);
          })
          .catch((err) => {
            console.error(err);
          });


        return;
      }
      //requesting permission using Notification API
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: firebaseConfig.vapidKey,
        });

        //We can send token to server
        UserDeviceService.getInstance()
          .store({
            name: navigator.appName,
            version: navigator.appVersion,
            language: navigator.language,
            platform: navigator.platform,
            user_agent: navigator.userAgent,
            token,
          })
          .then(() => {
            // console.log("Device info stored : ", response);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
      }
    }
    if ('PushManager' in window) {
      requestPermission();
    }

  }, [user]);


  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    // disable zoom
    document.addEventListener("touchstart", preventZoom, { passive: false });
    return () => {
      document.removeEventListener("touchstart", preventZoom);
    };
  }, []);

  const loadNotifications = useCallback(() => {
    if (user?.uid) {
      NotificationService.getInstance()
        .findUnreadByUser(user.uid)
        .then((data) => {
          setNotifications(data.unreadNotifications);
          setUnreadNotificationsCount(data.unreadNotificationsCount);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const loadPendingRelations = useCallback(() => {
    if (user?.uid) {
      RelationService.getInstance()
        .pendingReceived(user.uid)
        .then((response) => {
          setPendingRelations(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const loadUnreadMessagesCount = useCallback(() => {
    if (user?.uid) {
      MessageService.getInstance()
        .findUnreadByUser(user.uid)
        .then((data) => {
          setUnreadMessagesCount(data.unreadMessagesCount);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const loadCurrentUser = useCallback(() => {
    UserService.getCurrentUser()
      .then((response) => {
        let data = Helper.handleResponse(response);
        setUser(data);
      })
      .catch(() => {
        // not log this error
      });
  }, []);

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  // implementing socket io
  React.useEffect(() => {
    if (!user) return;

    loadNotifications();
    loadUnreadMessagesCount();
    loadPendingRelations();

    const socket = io(process.env.REACT_APP_SOCKET_URL);

    const setupSocket = () => {

      socket.on("connect", () =>
        socket.emit("join", `App.Models.User.${user.id}`)
      );
      socket.on("disconnect", () =>
        socket.emit("leave", `App.Models.User.${user.id}`)
      );

      socket.on("events", (data) => {
        loadNotifications();
      });

      socket.on("conversations", (data) => {
        loadUnreadMessagesCount();
        Toastr.success(
          `Vous avez un nouveau message de ${data.conversation_member_name}`
        );
      });

      socket.on("feed", (uid) => {
        ActualiteService.getInstance()
          .find(uid)
          .then((data) => {
            Toastr.success(`Vous avez de nouvelles actualités`);
            const dataCopy = { ...data, hot: true };
            dispatch(addOneActualite(dataCopy));
          })
          .catch(console.error);
      });
    };

    const cleanupSocket = () => {
      socket.off("events");
      socket.off("conversations");
      socket.off("feed");
      socket.disconnect();
    };

    setupSocket();
    return cleanupSocket;
  }, [
    dispatch,
    user,
    loadNotifications,
    loadUnreadMessagesCount,
    loadPendingRelations,
  ]);

  return (
    <ConfigProvider
      locale={frFR}
      theme={{
        token: {
          colorPrimary: "#356efe",
        },
      }}
    >
      <UserContext.Provider
        value={{
          user,
          setUser,
          loadCurrentUser,
          loadPendingRelations,
          pendingRelations,
        }}
      >
        <UnreadNotificationsContext.Provider
          value={{
            notifications,
            loadNotifications,
            unreadNotificationsCount,
            unreadMessagesCount,
            loadUnreadMessagesCount,
          }}
        >
          <MeetingContext.Provider
            value={{ currentMeeting, setCurrentMeeting }}
          >
            <MessagingContext.Provider
              value={{ showMessagingDrawer, setShowMessagingDrawer }}
            >
              <Router>
                <HeaderMain />
                {/* <Banner /> */}
                <ScrollToTop>
                  <Suspense fallback={<SkeletonAnimated nombre={7} />}>
                    <Routes>
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        element={<FeedHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-login"}`}
                        element={<LoginPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/become-instructor"
                          }`}
                        element={<BecomeInstructor />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/demande-instructeur"
                          }`}
                        element={<DemandeInstructeurList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructeur-cours/:uid"
                          }`}
                        element={<InstructeurCoursList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-conception-show/:uid"
                          }`}
                        element={<CoursConceptionShow />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/demande-diffusion-cours"
                          }`}
                        element={<DemandePublicationCoursAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/event-details/:uid"
                          }`}
                        element={<EvenementDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructeur-classes/:uid"
                          }`}
                        element={<InstructeurClasseList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/classe-details/:uid"
                          }`}
                        element={<ClasseDetails />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/evaluation-new"}`}
                        element={<EvaluationNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/public-profil/:uid"
                          }`}
                        element={<PublicProfilePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cv-builder"}`}
                        element={<CvBuilderPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecoles"}`}
                        element={<EcoleHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecole/:slug"}`}
                        element={<EcoleDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-evenements/:uid"
                          }`}
                        element={<UserEvenementList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/admin-events"}`}
                        element={<AdminEventList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/event-new"}`}
                        element={<EvenementNew />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/event-edit/:uid"}`}
                        element={<EvenementEdit />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/event-config/:uid"
                          }`}
                        element={<EvenementConfig />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/apprenant-classes/:uid"
                          }`}
                        element={<ApprenantClasseList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/classe-config/:uid"
                          }`}
                        element={<ClasseConfigPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/join-classe/:uid/temporal-link"
                          }`}
                        element={<JoinClassePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-consentements"
                          }`}
                        element={<UserConsentements />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/classe-chapitre/:uid"
                          }`}
                        element={<ClasseChapitreDeroulementPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-chapitre/:uid"
                          }`}
                        element={<CoursChapitreDeroulementPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/evaluation-config/:uid"
                          }`}
                        element={<EvaluationConfigPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/instructeur-evaluations/:uid"
                          }`}
                        element={<InstructeurEvaluationListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/admin-evaluations"
                          }`}
                        element={<AdminEvaluationListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/evaluation-deroulement/:uid"
                          }`}
                        element={<EvaluationDeroulementPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/notifications"}`}
                        element={<NotificationListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/payment-confirmation"
                          }`}
                        element={<PaymentResult />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/groupe-new"}`}
                        element={<GroupeNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/groupe-details/:uid"
                          }`}
                        element={<GroupeDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/groupe-edit/:uid"}`}
                        element={<GroupeEditPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/groups"}`}
                        element={<GroupeHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/tasks"}`}
                        element={<TacheHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/classrooms"
                          }`}
                        element={<ClasseHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/trailmix"
                          }`}
                        element={<ParcoursHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/chapitre-content/:uid"
                          }`}
                        element={<ChapitrePublicContentPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/publication-details/:uid"
                          }`}
                        element={<PublicationShowPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/publication-edit/:uid"
                          }`}
                        element={<PublicationEditPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-page"}`}
                        element={<CourseByCategoriePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/categorie-courses/:uid"
                          }`}
                        element={<ElearningCourseCategorieList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-taches/:uid"}`}
                        element={<TacheListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/campagne-list"}`}
                        element={<CampagneAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/campagne-details/:uid"
                          }`}
                        element={<CampagneDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/comment-admin-list"
                          }`}
                        element={<CommentAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/commentaire-details/:uid"
                          }`}
                        element={<CommentaireDetailsRedirect />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cv-preview/:uid"}`}
                        element={<CvPublicPreviewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/admin-cvs"}`}
                        element={<CvAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/reseau"}`}
                        element={<ReseauHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecole-new"}`}
                        element={<EcoleNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/course-details/:uid"
                          }`}
                        element={<CourseDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-edit/:uid"}`}
                        element={<CoursEditPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/article-details/:uid"
                          }`}
                        element={<ArticleShowPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructeur-articles/:uid"
                          }`}
                        element={<InstructeurArticleListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/article-new"}`}
                        element={<ArticleNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/article-edit/:uid"
                          }`}
                        element={<ArticleEditPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructor-consentements"
                          }`}
                        element={<InstructorConsentementsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/messaging"}`}
                        element={<MessagingHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/messaging/:uid"}`}
                        element={<MessaginDetailsHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-new"}`}
                        element={<CoursNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/events"}`}
                        element={<EventHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/papers"}`}
                        element={<PapersHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/elearning"}`}
                        element={<ElearningHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-consentements"
                          }`}
                        element={<UserConsentements />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/public-profil/:uid"
                          }`}
                        element={<PublicProfilePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructor-consentements"
                          }`}
                        element={<InstructorConsentementsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecole-new"}`}
                        element={<EcoleNewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecoles"}`}
                        element={<EcoleHome />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/ecole/:slug"}`}
                        element={<EcoleDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/campagne-list"}`}
                        element={<CampagneAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/campagne-details/:uid"
                          }`}
                        element={<CampagneDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/coupon-promotion-admin-list"
                          }`}
                        element={<CouponPromotionListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/demande-support-tutorat-admin-list"
                          }`}
                        element={<DemandeSupportTutoratAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/demande-support-tutorat-details/:uid"
                          }`}
                        element={<DemandeSupportTutoratDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/parcours-admin-list"
                          }`}
                        element={<ParcoursAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/parcours-admin/:slug"
                          }`}
                        element={<ParcoursAdminShowPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/parcours-details/:slug"
                          }`}
                        element={<ParcoursPublicDetailsPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/apprenant-parcours/:uid"
                          }`}
                        element={<ParcoursAapprenantListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cv-builder"}`}
                        element={<CvBuilderPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cv-preview/:uid"}`}
                        element={<CvPublicPreviewPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/admin-cvs"}`}
                        element={<CvAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-learning/:uid"
                          }`}
                        element={<CoursDeroulement />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL +
                          "/apprenant-souscriptions/:uid"
                          }`}
                        element={<ApprenantSouscriptionList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-favoris/:uid"
                          }`}
                        element={<CoursFavoriList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/instructeur-space/:uid"
                          }`}
                        element={<InstructeurSpacePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-profil"}`}
                        element={<UserProfil />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/user-admin-list"}`}
                        element={<UserAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/cours-admin-list"}`}
                        element={<CoursAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/review-admin-list"
                          }`}
                        element={<ReviewsAdminList />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/categories-selector"
                          }`}
                        element={<CategorieChoicesPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/follow-users"}`}
                        element={<FollowUsersPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/meet"}`}
                        element={<MeetHomePage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/meet-details/:uid"
                          }`}
                        element={<MeetConfigPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/meet-admin-list"}`}
                        element={<MeetAdminListPage />}
                      />
                      <Route
                        exact
                        path={`${process.env.PUBLIC_URL + "/quiz"}`}
                        element={<QuizHomePage />}
                      />
                      <Route path="*" element={<FeedHomePage />} />
                    </Routes>
                  </Suspense>
                </ScrollToTop>
                <NotificationReminder />
                <TacheDrawer />
                <ProjetDrawer />
                <MeetingDrawer />
                <MessagingDrawer />
                <BottomNav />
              </Router>
            </MessagingContext.Provider>
          </MeetingContext.Provider>
        </UnreadNotificationsContext.Provider>
      </UserContext.Provider>
    </ConfigProvider>
  );
}

export default App;
