import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ParallaxProvider } from "react-scroll-parallax";
import React from "react";
// import { StrictMode } from "react";

import "animate.css/animate.min.css";
import { Provider } from 'react-redux'
import appStore from "./store/appStore";
import App from "./App";

function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                //  console.log('Service Worker enregistré : ', registration);
            })
            .catch(error => {
                console.log('Erreur lors de l’enregistrement du Service Worker : ', error);
            });
        if ('PushManager' in window) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then(function (registration) {
                   // console.log('Registration successful, scope is:', registration.scope);
                }).catch(function (err) {
                    console.log('Service worker registration failed, error:', err);
                });
        }
    }
}

/*function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}*/

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(
    // <StrictMode>
    <ParallaxProvider>
        <Provider store={appStore}>
            <App />
        </Provider>
    </ParallaxProvider>
    //  </StrictMode>
);

registerServiceWorker();
// unregister();


// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
