import { createSlice } from '@reduxjs/toolkit'

export const categorieWithPopularCoursesReducer = createSlice({
  name: 'categorieWithPopCourses',
  initialState: {
    nextPageUrl: null,
    items: [],
  },
  reducers: {
    updateCategorieWithPopCourses: (state, action) => {
      const payload = action.payload;
      state.items = [...state.items, ...payload.data];
      state.nextPageUrl = payload.next_page_url;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateCategorieWithPopCourses } = categorieWithPopularCoursesReducer.actions

export default categorieWithPopularCoursesReducer.reducer