import DaoService from './DaoService';
export default class MessageService extends DaoService {
    static instance;

    constructor({ prefix }) {
        super(prefix);
    }

    static getInstance() {
        if (!MessageService.instance) {
            MessageService.instance = new MessageService({ prefix: '/message' });
        }
        return MessageService.instance;
    }

    findUnreadByUser(uid) {
        return DaoService.get(`${this.prefix}-unread/${uid}`);
    }

    findByConversation(uid) {
        return DaoService.get(`${this.prefix}/${uid}/conversation`);
    }
}
